import ShippingItem from "@components/order/shipping/ShippingItem";
import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";

const ShippingItemHeader = ({
	shipping: {
		typeCode,
		label,
		options,
		cheapestOptionPrice,
		expiresAt,
		hasDifferentPrices,
	},
	isSelected = false,
	enableConfirmButton,
	active,
}) => {
	const transport = [
		...new Set(options.map((option) => option?.transporter)),
	].filter(Boolean);

	return (
		<ShippingItem
			className={typeCode}
			name={typeCode.toLowerCase()}
			label={label}
			price={cheapestOptionPrice}
			transport={transport}
			expirationDate={expiresAt}
			isSelected={isSelected}
			enableConfirmButton={enableConfirmButton}
			active={active}
		>
			<FormattedMessage
				id="shipping.monetary.from"
				values={{
					priceFrom: hasDifferentPrices,
					value: cheapestOptionPrice,
				}}
			/>
		</ShippingItem>
	);
};

ShippingItemHeader.propTypes = {
	shipping: PropTypes.object,
	isSelected: PropTypes.bool,
	enableConfirmButton: PropTypes.func,
	recipientData: PropTypes.object,
	openRecipientForm: PropTypes.func,
	active: PropTypes.bool,
};

export default ShippingItemHeader;
