import { Divider } from "@components/v2/components/dividers/Divider";
import { getLinkStyles } from "@components/v2/components/link/Link.styles";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { FlexConsent } from "@komplettnpm/mfcomponents";

export const StyledFlexConsent = styled(FlexConsent)(
	({ theme }) => css`
		& a,
		p,
		h1,
		label {
			line-height: 1.5;
			margin-top: 16px;
		}

		& h1 {
			font-weight: bold;
			font-size: 16px;
		}

		& a {
			${getLinkStyles({ theme })}
		}

		& label {
			margin-bottom: 16px;
		}
	`,
);

export const FairownDivider = styled(Divider)`
	margin: 16px 0;
`;

export const FairownSubscriptionAgreementsWrapper = styled.article`
	& a,
	p {
		font-size: 14px;
		line-height: 130%;
	}
`;
