import { enableConfirmButton } from "@actions/checkout.actions";
import { selectShipping } from "@actions/shipping.actions";
import AlertsContainer from "@components/alerts/AlertsContainer";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Shipping from "./shipping/Shipping";

const Order = () => {
	const dispatch = useDispatch();

	const logisticUnit = useSelector((state) => state.order.logisticUnits?.[0]);
	const isHidden = useSelector((state) => state.order.isHidden);
	const isSectionDisabled = useSelector(
		(state) => state.order.isSectionDisabled,
	);
	const porterBuddyInstructions = useSelector(
		(state) => state.order.porterBuddyInstructions,
	);
	const recipientData = useSelector((state) => state.recipient.data);
	const handleSelectShipping =
		(logisticUnitId) => (methodId, optionId, extraServiceIds) =>
			dispatch(
				selectShipping(logisticUnitId, methodId, optionId, extraServiceIds),
			);

	if (isHidden) {
		return null;
	}

	return (
		<>
			{logisticUnit?.shipping && (
				<Shipping
					{...logisticUnit.shipping}
					id={logisticUnit.id}
					isSectionDisabled={isSectionDisabled}
					onSelectMethod={handleSelectShipping(logisticUnit.id)}
					porterBuddyInstructions={porterBuddyInstructions}
					enableConfirmButton={enableConfirmButton}
					recipientData={recipientData}
				/>
			)}
			<AlertsContainer role="checkout.order.general" />
		</>
	);
};

export default Order;
