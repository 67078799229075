import AlertsContainer from "@components/alerts/AlertsContainer";
import Dropdown from "@components/form/Dropdown";
import ShippingExtraServices from "@components/order/shipping/ShippingExtraServices";
import ShippingItemHeader from "@components/order/shipping/ShippingItemHeader";
import ShippingCarryin from "@components/order/shipping/carryin/ShippingCarryin";
import Panel from "@components/payment/Panel";
import { Divider } from "@components/v2/components/dividers/Divider";
import Section from "@components/v2/components/section/Section";
import {
	SectionContent,
	SectionHeader,
	SectionLabel,
	SectionNumber,
} from "@components/v2/components/section/section.styles";
import {
	TYPECODES,
	PORTER_BUDDY_CHAR_LIMIT,
	DISABLE_DROPDOWN_OPTIONS_LIMIT,
	SECTIONS_NAMES,
} from "@constants/constants";
import { GTM_EVENT_NAMES, pushToDataLayerAsync } from "@utils/GTMUtils";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useMemo, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { useElementObserver } from "../../../customHooks/useElementObserver";

import PorterInstructions from "./PorterInstructions";
import ShippingItem from "./ShippingItem";

const Shipping = ({
	id,
	currentMethod,
	currentOption,
	extraServiceIds,
	onSelectMethod,
	porterBuddyInstructions,
	isSectionDisabled,
	enableConfirmButton,
}) => {
	const shippingRef = useRef(null);
	const isRenewalMode = useSelector((state) => state.checkout.isRenewalMode);
	const selectShippingState = (state) => state.order.logisticUnits[0].shipping;
	const availableShipping = useSelector(
		(state) => selectShippingState(state).availableShipping,
	);
	const { formatMessage } = useIntl();
	const shippingPerTransport = useMemo(() => {
		return availableShipping.reduce((previousValue, currentValue) => {
			const extractedOptions = currentValue.options.map(
				(option) => `${currentValue.typeCode} ${option.transporter}`,
			);
			return [...previousValue, ...[...new Set(extractedOptions)]];
		}, []);
	}, [availableShipping]);

	const selectMethod = (methodId) => {
		const selectedMethod = availableShipping.find(
			(shipping) => shipping.typeCode === methodId,
		);
		onSelectMethod(methodId, selectedMethod.options[0].id);
	};

	const handleShippingClick = (extraServiceIds) => {
		onSelectMethod(currentMethod.typeCode, currentOption.id, extraServiceIds);
	};

	const handleActivatePanel = () => (typeCode) => {
		selectMethod(typeCode);
	};

	const handleSelectOption = (methodId) => (optionId) => {
		onSelectMethod(methodId, optionId);
	};

	useElementObserver(shippingRef, () => {
		pushToDataLayerAsync({
			data: {
				event: GTM_EVENT_NAMES.SHIPPING_VISITED,
				options: shippingPerTransport.join(", "),
			},
		});
	});

	return (
		<Section
			id={SECTIONS_NAMES.SHIPPING}
			className="shipping"
			ref={shippingRef}
		>
			<SectionHeader>
				{!isRenewalMode && <SectionNumber>{2}</SectionNumber>}
				<SectionLabel>
					{formatMessage({ id: "order.shipping.methods.label" })}
				</SectionLabel>
			</SectionHeader>
			<Divider />
			<SectionContent
				className="methods"
				data-automationid="shippingMethods"
				role="radiogroup"
			>
				{availableShipping.map((shipping, i) => {
					const isSelected = currentMethod.typeCode === shipping.typeCode;
					const hasDifferentTransporters =
						new Set(shipping.options.map((option) => option.transporter)).size >
						1;
					const hasDeliveryWindow = shipping.options.some(
						(option) => !!option.hours,
					);
					const hasGreenDeliveryLabel = shipping.options.some(
						(option) => !!option.greenDeliveryLabel,
					);

					switch (shipping.typeCode) {
						case TYPECODES.CARRYIN:
							return (
								<Panel
									key={`${shipping.typeCode}-${i}`}
									id={shipping.typeCode}
									automationid={shipping.typeCode}
									active={shipping.typeCode === currentMethod.typeCode}
									automationIdSection="shipping"
									onActivate={handleActivatePanel(shipping.typeCode)}
									onContentClick={(e) => {
										e.stopPropagation();
									}}
									disabled={isSectionDisabled}
									collapsible={false}
									header={
										<ShippingItemHeader
											shipping={shipping}
											isSelected={isSelected}
											enableConfirmButton={enableConfirmButton}
										/>
									}
								>
									<ShippingCarryin />
								</Panel>
							);
						default:
							return (
								<Panel
									key={`${shipping.typeCode}-${i}`}
									id={shipping.typeCode}
									className={classNames({
										"delivery-window": hasDeliveryWindow,
									})}
									automationid={shipping.typeCode}
									active={shipping.typeCode === currentMethod.typeCode}
									automationIdSection="shipping"
									onActivate={handleActivatePanel(shipping.typeCode)}
									disabled={isSectionDisabled}
									header={
										<ShippingItemHeader
											shipping={shipping}
											isSelected={isSelected}
											enableConfirmButton={enableConfirmButton}
										/>
									}
								>
									{currentMethod.hasAlternatives && (
										<Dropdown
											id={`shippingOptions-${id}`}
											className={classNames("available-items", {
												"delivery-window": hasDeliveryWindow,
												"has-green-delivery": hasGreenDeliveryLabel,
											})}
											selectedId={currentOption.id}
											onSelect={handleSelectOption(shipping.typeCode)}
											active={
												currentMethod.options.length <
												DISABLE_DROPDOWN_OPTIONS_LIMIT
											}
											horizontal={
												currentMethod.options.length <
												DISABLE_DROPDOWN_OPTIONS_LIMIT
											}
											disabled={isSectionDisabled}
										>
											{currentMethod.options.map((option) => {
												return (
													<ShippingItem
														key={option.id}
														className={classNames(shipping.typeCode, {
															"delivery-window": hasDeliveryWindow,
															"has-green-delivery": hasGreenDeliveryLabel,
														})}
														label={
															currentMethod.hasDifferentNames && option.label
														}
														header={option.header}
														subHeader={option.subHeader}
														greenDeliveryLabel={option.greenDeliveryLabel}
														price={
															currentMethod.hasDifferentPrices && option.price
														}
														transport={
															hasDifferentTransporters
																? [option.transporter]
																: []
														}
														postCode={option.postCode}
														street={option.street}
														hours={option.hours}
														estimatedDeliveryDate={option.estimatedDeliveryDate}
														estimatedDeliveryTime={option.estimatedDeliveryTime}
														isSelected={option.id === currentOption.id}
													>
														<FormattedMessage
															id="shortMonetary"
															values={{ value: option.price }}
														/>
													</ShippingItem>
												);
											})}
										</Dropdown>
									)}
									{!!currentOption.extraServices.length && (
										<ShippingExtraServices
											extraServices={currentOption.extraServices}
											extraServiceIds={extraServiceIds}
											onExtraServiceCheck={handleShippingClick}
											isDisabled={isSectionDisabled}
										/>
									)}
									{currentOption && currentOption.description && (
										<p
											className="extra-message"
											/* eslint-disable-next-line react/no-danger */
											dangerouslySetInnerHTML={{
												__html: currentOption.description,
											}}
										/>
									)}
									{currentMethod.typeCode === TYPECODES.PORTER && (
										<PorterInstructions
											instructionsValue={porterBuddyInstructions}
											characterLimit={PORTER_BUDDY_CHAR_LIMIT}
											placeholder={formatMessage({
												id: "porterbuddy.delivery.placeholder",
											})}
										/>
									)}
									{currentOption &&
										currentOption.estimatedDeliveryDate &&
										!currentMethod.hasAlternatives && (
											<span
												className="estimated-delivery"
												/* eslint-disable-next-line react/no-danger */
												dangerouslySetInnerHTML={{
													__html: currentOption.estimatedDeliveryDate,
												}}
											/>
										)}
								</Panel>
							);
					}
				})}
				{availableShipping.length === 1 && (
					<span className="one-method-available-message">
						<FormattedMessage id="order.shipping.methods.noother" />
					</span>
				)}
			</SectionContent>
			<AlertsContainer
				role="checkout.shipping.general"
				disabled={isSectionDisabled}
			/>
		</Section>
	);
};

Shipping.propTypes = {
	id: PropTypes.string,
	currentMethod: PropTypes.object,
	currentOption: PropTypes.object,
	extraServiceIds: PropTypes.array,
	onSelectMethod: PropTypes.func,
	porterBuddyInstructions: PropTypes.string,
	isSectionDisabled: PropTypes.bool,
	enableConfirmButton: PropTypes.func,
};

export default Shipping;
