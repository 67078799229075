import * as CheckoutActions from "@actions/checkout.actions";
import * as SummaryActions from "@actions/summary.actions";
import AlertsContainer from "@components/alerts/AlertsContainer";
import OrderItems from "@components/order/OrderItems";
import RenewalHeader from "@components/v2/components/Renewal/RenewalHeader/RenewalHeader";
import RenewalSteps from "@components/v2/components/Renewal/RenewalSteps/RenewalSteps";
import {
	STORE_IDS,
	STATUS_NAMES,
	SECTIONS_NAMES,
	TYPECODES,
} from "@constants/constants";
import { sendPaymentEvent } from "@utils/GTMUtils";
import classNames from "classnames";
import React, { useRef, useState } from "react";
import { FormattedMessage, FormattedHTMLMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import AppLoader from "../app/loaders/AppLoader";
import SpinningCircle from "../button/loaders/SpinningCircle";
import ReactCheckbox from "../form/ReactCheckbox";
import Order from "../order/Order";
import Recipient from "../recipient/Recipient";
import Summary from "../summary/Summary";
import Terms from "../terms/Terms";
import Payment from "../v2/components/payments/Payment";

import Header from "./Header";
import ReceiptPage from "./receiptPage/ReceiptPage";

const ConfirmOrderButton = ({ setHighlightCheckbox }) => {
	const dispatch = useDispatch();
	const intl = useIntl();

	const [disableConfirmButton, setDisableConfirmButton] = useState(false);

	const payment = useSelector((state) => state.payment);
	const isPaymentValid = useSelector((state) => state.payment.isValid);

	const summary = useSelector((state) => state.summary);

	const isRecipientValid = useSelector((state) => state.recipient.isValid);

	const storeId = useSelector((state) => state.checkout.storeId);
	const isBlurred = useSelector((state) => state.checkout.isBlurred);
	const agreedToTerms = useSelector((state) => state.checkout.agreedToTerms);
	const isConfirmButtonDisabled = useSelector(
		(state) => state.checkout.isConfirmButtonDisabled,
	);
	const isCoveredByGiftCard = useSelector(
		(state) => state.checkout.isCoveredByGiftCard,
	);
	const isCoveredByBonusPoints = useSelector(
		(state) => state.checkout.isCoveredByBonusPoints,
	);

	const isCoveredByGiftCardsAndBonusPoints = useSelector(
		(state) => state.checkout.isCoveredByGiftCardsAndBonusPoints,
	);

	const porterBuddyInstructions = useSelector(
		(state) => state.order.porterBuddyInstructions,
	);
	const typeCode = useSelector(
		(state) => state.order.logisticUnits[0]?.shipping.selectedShipping.typeCode,
	);
	const isOrderValid = useSelector((state) => state.order.isValid);

	const isPorterBuddySelected = typeCode === TYPECODES.PORTER;
	const appValidationStatus = [
		{ name: SECTIONS_NAMES.RECIPIENT, isValid: isRecipientValid },
		{ name: SECTIONS_NAMES.ORDER, isValid: isOrderValid },
		{ name: SECTIONS_NAMES.PAYMENT, isValid: isPaymentValid },
	];
	const isRenewalMode = useSelector((state) => state.checkout.isRenewalMode);

	const renewalSubmitButtonLabelId = "submit.with.subscription.renewal";
	const submitButtonLabelId = summary.subscriptionPaymentSummary
		? "submit.with.subscription"
		: "submit";

	const handleClickConfirmButton = (e) => {
		e.preventDefault();
		e.stopPropagation();

		if (storeId === STORE_IDS.KOMPLETT_DK && !agreedToTerms) {
			setHighlightCheckbox(true);
			return;
		}

		if (isConfirmButtonDisabled) {
			dispatch(CheckoutActions.alertCountDownExpired());
			return;
		}

		let isEnabled = appValidationStatus.every((section) => section.isValid);
		const _porterBuddyInstructions = isPorterBuddySelected
			? porterBuddyInstructions
			: null;

		if (isEnabled) {
			setDisableConfirmButton(true);
			sendPaymentEvent("PayAndFinishClicked").then(() =>
				dispatch(
					SummaryActions.confirmCheckout({
						isCoveredByGiftCard,
						isCoveredByBonusPoints,
						isCoveredByGiftCardsAndBonusPoints,
						payment,
						intl,
						porterBuddyInstructions: _porterBuddyInstructions,
					}),
				).finally(() => {
					return setDisableConfirmButton(false);
				}),
			);
		} else {
			const invalidSections = appValidationStatus
				.filter((status) => !status.isValid)
				.map((element) => element.name);

			if (invalidSections.length > 0) {
				document.getElementById(invalidSections[0]).scrollIntoView();

				if (invalidSections[0] === SECTIONS_NAMES.PAYMENT) {
					document
						.getElementById(invalidSections[0])
						.querySelectorAll("form > section:not(.confirmations) input")
						.forEach((input) => {
							input.focus();
						});
				}
			}
		}
	};

	return (
		<button
			className={classNames("confirm-button", {
				"is-sending": summary.isSending,
				disabled:
					isConfirmButtonDisabled ||
					(storeId === STORE_IDS.KOMPLETT_DK && !agreedToTerms),
			})}
			onClick={handleClickConfirmButton}
			tabIndex={isBlurred ? -1 : 0}
			disabled={disableConfirmButton}
			data-automationid="confirmButton"
		>
			{summary.isSending && <SpinningCircle className="loader" />}
			{intl.formatMessage(
				{
					id: isRenewalMode ? renewalSubmitButtonLabelId : submitButtonLabelId,
				},
				{
					value: summary.subscriptionPaymentSummary
						? null
						: summary.directPaymentSummary.total.value,
				},
			)}
		</button>
	);
};

const ConfirmOrderSection = () => {
	const dispatch = useDispatch();
	const intl = useIntl();

	const sectionElement = useRef(null);

	const [highlightCheckbox, setHighlightCheckbox] = useState(false);

	const payment = useSelector((state) => state.payment);

	const summary = useSelector((state) => state.summary);

	const storeId = useSelector((state) => state.checkout.storeId);
	const agreedToTerms = useSelector((state) => state.checkout.agreedToTerms);
	const isRenewalMode = useSelector((state) => state.checkout.isRenewalMode);

	const isFlexLayout =
		summary.subscriptionPaymentSummary && payment.subscriptionMethod;

	const handleAgreeTermsChange = () => {
		setHighlightCheckbox(false);
		dispatch(CheckoutActions.toggleAgreeTerms());
	};

	return (
		!summary.isHidden && (
			<>
				<section
					id={SECTIONS_NAMES.CONFIRMATION}
					ref={sectionElement}
					className={classNames("confirmation", {
						"flex-layout": isFlexLayout,
						"renewal-layout": isRenewalMode,
					})}
					aria-label={intl.formatMessage({ id: "finish.section.title" })}
				>
					{storeId === STORE_IDS.KOMPLETT_DK && (
						<section
							className={classNames("agree-to-terms", {
								highlight: highlightCheckbox,
							})}
						>
							<ReactCheckbox
								value={agreedToTerms}
								handleChange={handleAgreeTermsChange}
								automationId="denmark.agreeToTerms"
								id="denmark.agreeToTerms"
								label={
									<FormattedHTMLMessage id="summary.denmark.agreeToTerms" />
								}
							/>
						</section>
					)}
					<ConfirmOrderButton setHighlightCheckbox={setHighlightCheckbox} />
				</section>
				<section className="selling-points">
					<ul>
						{summary.sellingPoints.map((item, index) => {
							return (
								<li className="item" key={index}>
									<FormattedMessage id={item} />
								</li>
							);
						})}
					</ul>
				</section>
			</>
		)
	);
};

const App = () => {
	const subscriptionMethod = useSelector(
		(state) => state.payment.subscriptionMethod,
	);

	const customerType = useSelector((state) => state.recipient.customerType);

	const status = useSelector((state) => state.checkout.status);
	const cartUrl = useSelector((state) => state.checkout.cartUrl);
	const storeUrl = useSelector((state) => state.checkout.storeUrl);
	const storeId = useSelector((state) => state.checkout.storeId);
	const isDisabled = useSelector((state) => state.checkout.isDisabled);
	const isBlurred = useSelector((state) => state.checkout.isBlurred);
	const isLoading = useSelector((state) => state.checkout.isLoading);
	const isRenewalMode = useSelector((state) => state.checkout.isRenewalMode);

	const subscriptionPaymentSummary = useSelector(
		(state) => state.summary.subscriptionPaymentSummary,
	);

	const summaryIsHidden = useSelector((state) => state.summary.isHidden);

	const isFlexLayout = subscriptionPaymentSummary && subscriptionMethod;

	return (
		<div className={classNames("wrapper", { disabled: isDisabled })}>
			<Header
				showBackToCartLink={status !== STATUS_NAMES.PLACED}
				cartUrl={cartUrl}
				storeUrl={storeUrl}
				storeId={storeId}
			/>
			<AlertsContainer role="checkout.general" disabled={isDisabled} />
			<AppLoader show={isLoading} />
			<main
				className={classNames("main", {
					"flex-layout": isFlexLayout,
					"renewal-layout": isRenewalMode,
					loading: isLoading,
				})}
			>
				{isRenewalMode && (
					<>
						<RenewalSteps />
						<RenewalHeader />
					</>
				)}
				{status === STATUS_NAMES.PLACED && <ReceiptPage />}
				<Recipient />
				<Order />
				<Payment />
				<section
					id={SECTIONS_NAMES.SUMMARY}
					className="section-summary-and-items"
				>
					<section>
						<OrderItems />
						<Summary />
					</section>
				</section>
				<Summary
					className={classNames("mobile", {
						"flex-layout": isFlexLayout,
						"renewal-layout": isRenewalMode,
					})}
				/>
				<OrderItems className="mobile" />
				<ConfirmOrderSection />
				{isBlurred && <div className="blur" />}
			</main>
			{!summaryIsHidden && (
				<Terms
					trigger={<FormattedMessage id="footer.termsandconditions.link" />}
					customerType={customerType}
					storeId={storeId}
				/>
			)}
		</div>
	);
};

export default App;
