import { selectMethod } from "@actions/payment/methods.actions";
import PaymentHeader from "@components/payment/PaymentHeader";
import SwishPaymentMethod from "@components/payment/methods/SwishPaymentMethod";
import BonusPointsContainer from "@components/payment/methods/bonusPoints/BonusPointsContainer";
import FinancePaymentMethod from "@components/payment/methods/financePaymentMethod/FinancePaymentMethod";
import {
	CollapsiblePanel,
	CollapsiblePanelContent,
	CollapsablePanelHeaderRadio,
	Panels,
} from "@components/v2/components/collapsiblePanel/collapsiblePanel.styles";
import { Divider } from "@components/v2/components/dividers/Divider";
import { PAYMENT_METHOD_NAMES } from "@constants/constants";
import dispatchClarityEvent from "@utils/MicrosoftClarityUtils";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { usePrevious } from "../../../../customHooks/usePrevious";

export const DirectPaymentMethods = () => {
	const dispatch = useDispatch();

	const giftCards = useSelector((state) => state.payment.giftCards);

	const bonusPoints = useSelector((state) => state.payment.bonusPoints);

	const methods = useSelector(
		({ payment: { directMethods, directMethodsOrder } }) =>
			directMethodsOrder.map((methodKey) => ({ ...directMethods[methodKey] })),
	);

	const selectedMethod = useSelector(
		({ payment: { directMethods, selectedMethod } }) =>
			directMethods[selectedMethod],
	);

	const isLoading = useSelector((state) => state.checkout.isLoading);
	const previousSelectedMethodType = usePrevious(selectedMethod.type);

	const selectPaymentMethod = useCallback(
		({ type, allowsGiftCard }) => {
			const shouldRemoveGiftCard = !allowsGiftCard && !!giftCards.length;

			dispatch(selectMethod(type, shouldRemoveGiftCard));
		},
		[dispatch, giftCards.length],
	);

	const handleSelectMethod = (method) => selectPaymentMethod(method);
	const handleOnClick = (e) => {
		if (e.target.value === previousSelectedMethodType) {
			dispatchClarityEvent("tryReactivatePaymentMethod");
		}
	};

	return (
		<Panels id="PaymentMethod">
			{methods.map((method) => {
				const isSelectedMethodNotChanged =
					method.type === previousSelectedMethodType;
				const isMethodSelected = method.type === selectedMethod.type;
				const isMethodPanelExpanded =
					isSelectedMethodNotChanged || (!isLoading && isMethodSelected);
				const isDisabled = isLoading && method.type !== selectedMethod.type;

				switch (method.type) {
					case PAYMENT_METHOD_NAMES.INVOICE:
					case PAYMENT_METHOD_NAMES.DEFERRED:
					case PAYMENT_METHOD_NAMES.INSTALLMENT:
					case PAYMENT_METHOD_NAMES.KOMPLETT_SAP_INVOICE:
					case PAYMENT_METHOD_NAMES.VIPPS:
					case PAYMENT_METHOD_NAMES.FAIROWN_SUBSCRIPTION:
					case PAYMENT_METHOD_NAMES.WALLEY_DEFERRED:
					case PAYMENT_METHOD_NAMES.WALLEY_INVOICE:
					case PAYMENT_METHOD_NAMES.WALLEY_INSTALLMENT:
						return (
							<CollapsiblePanel
								id={method.type}
								key={method.type}
								expanded={isMethodPanelExpanded}
								trigger={
									<CollapsablePanelHeaderRadio
										id={`${method.type}-trigger`}
										name="PaymentMethod"
										value={method.type}
										checked={method.type === selectedMethod.type}
										onChange={() => handleSelectMethod(method)}
										onClick={handleOnClick}
										label={<PaymentHeader method={method} />}
										disabled={isDisabled}
									/>
								}
							>
								<Divider />
								<CollapsiblePanelContent>
									<FinancePaymentMethod
										type={method.type}
										version={method.version}
									/>
								</CollapsiblePanelContent>
							</CollapsiblePanel>
						);
					case PAYMENT_METHOD_NAMES.CREDIT_CARD:
						return (
							<CollapsiblePanel
								id={method.type}
								key={method.type}
								expanded={isMethodPanelExpanded}
								trigger={
									<CollapsablePanelHeaderRadio
										id={`${method.type}-trigger`}
										name="PaymentMethod"
										value={method.type}
										checked={method.type === selectedMethod.type}
										onChange={() => handleSelectMethod(method)}
										onClick={handleOnClick}
										label={<PaymentHeader method={method} />}
										disabled={isDisabled}
									/>
								}
							/>
						);
					case PAYMENT_METHOD_NAMES.SWISH:
						return (
							<CollapsiblePanel
								id={method.type}
								key={method.type}
								expanded={isMethodPanelExpanded}
								trigger={
									<CollapsablePanelHeaderRadio
										id={`${method.type}-trigger`}
										name="PaymentMethod"
										value={method.type}
										checked={method.type === selectedMethod.type}
										onChange={() => handleSelectMethod(method)}
										onClick={handleOnClick}
										label={<PaymentHeader method={method} />}
										disabled={isDisabled}
									/>
								}
							>
								<Divider />
								<CollapsiblePanelContent>
									<SwishPaymentMethod />
								</CollapsiblePanelContent>
							</CollapsiblePanel>
						);
					case PAYMENT_METHOD_NAMES.KOMPLETT_BANK_BONUS_POINTS_V2:
						return (
							<BonusPointsContainer
								key={method.type}
								isPaymentEligibleForBonusPoints={
									selectedMethod.komplettBonusPointsPaymentEnabled
								}
								bonusPoints={bonusPoints}
							/>
						);
					default:
						return null;
				}
			})}
		</Panels>
	);
};

export default DirectPaymentMethods;
