import {
	ALERT_LEVELS,
	PAYMENT_METHOD_NAMES,
	GIFTCARD_PAYMENT_METHOD_NAMES,
	BONUS_POINTS_NAME,
} from "@constants/constants";
import { sendPaymentEvent } from "@utils/GTMUtils";

import * as AlertActions from "./alert.actions";
import * as CheckoutActions from "./checkout.actions";
import authFetch from "./utils/common/authFetch";
import getCheckoutId from "./utils/common/getCheckoutId";
import handleErrorStatus from "./utils/common/handleErrorStatus";
import redirect from "./utils/common/redirect";

/* action types */

export const CHECKOUT_CONFIRM_FAILED = "CHECKOUT_CONFIRM_FAILED";
export const CHECKOUT_CONFIRM_CANCELLED = "CHECKOUT_CONFIRM_CANCELLED";

export const ORDER_PLACE_REQUESTED = "ORDER_PLACE_REQUESTED";
export const ORDER_PLACE_DONE = "ORDER_PLACE_DONE";
export const ORDER_PLACE_FAILED = "ORDER_PLACE_FAILED";

/* action creators */

const createOrderPayload = ({
	isCoveredByGiftCard,
	isCoveredByBonusPoints,
	isCoveredByGiftCardsAndBonusPoints,
	porterBuddyInstructions,
	payment: {
		directMethods: methods,
		selectedMethod: selectedMethodType,
		subscriptionMethod,
		ssn,
		giftCards,
		bonusPoints,
		swishPhoneNumber,
	},
}) => {
	const selectedMethod = methods[selectedMethodType];
	const selectedOption =
		selectedMethod?.options[selectedMethod.selectedOptionCode];

	let payments = [];
	let placeOrderRequest = { payments };
	const bonusPointsVersionType = methods[
		PAYMENT_METHOD_NAMES.KOMPLETT_BANK_BONUS_POINTS_V2
	]
		? PAYMENT_METHOD_NAMES.KOMPLETT_BANK_BONUS_POINTS_V2
		: BONUS_POINTS_NAME;

	if (
		!isCoveredByGiftCard &&
		!isCoveredByBonusPoints &&
		!isCoveredByGiftCardsAndBonusPoints
	) {
		switch (selectedMethodType) {
			case PAYMENT_METHOD_NAMES.INVOICE:
			case PAYMENT_METHOD_NAMES.DEFERRED:
			case PAYMENT_METHOD_NAMES.INSTALLMENT:
			case PAYMENT_METHOD_NAMES.KOMPLETT_SAP_INVOICE:
			case PAYMENT_METHOD_NAMES.VIPPS:
			case PAYMENT_METHOD_NAMES.CREDIT_CARD:
			case PAYMENT_METHOD_NAMES.FAIROWN_SUBSCRIPTION:
			case PAYMENT_METHOD_NAMES.WALLEY_DEFERRED:
			case PAYMENT_METHOD_NAMES.WALLEY_INSTALLMENT:
			case PAYMENT_METHOD_NAMES.WALLEY_INVOICE: {
				payments.push({
					type: selectedMethodType,
					productCode: selectedOption.code,
					ssn,
				});
				break;
			}
			case PAYMENT_METHOD_NAMES.SWISH: {
				payments.push({
					type: selectedMethodType,
					mobileNumber: swishPhoneNumber,
				});
				break;
			}
		}
	}
	if (!isCoveredByGiftCard && bonusPoints?.isUsed) {
		payments.push({
			type: bonusPointsVersionType,
			bonusPoints: bonusPoints.usedBonusPoints,
			amount: bonusPoints.usedAmount,
			bonusCardId: bonusPoints.cardId,
		});
	}

	if (giftCards.length > 0) {
		if (methods[GIFTCARD_PAYMENT_METHOD_NAMES.GIFT_CARD_RETAIN_24]) {
			payments.push({
				type: "GiftCardRetain24",
				amount: giftCards.reduce((acc, gc) => acc + gc.usedAmount, 0),
				appliedGiftCards: giftCards.map((gc) => ({
					id: gc.id,
					code: gc.code,
					amount: gc.usedAmount,
				})),
			});
		} else {
			payments.push({
				type: "GiftCard",
				amount: giftCards.reduce((acc, gc) => acc + gc.usedAmount, 0),
				amounts: giftCards.map((gc) => ({
					claimCode: gc.code,
					amount: gc.usedAmount,
				})),
			});
		}
	}
	if (subscriptionMethod) {
		payments.push({
			type: subscriptionMethod.type,
		});
	}
	if (porterBuddyInstructions) {
		placeOrderRequest.shippingInstructions = porterBuddyInstructions;
	}

	return placeOrderRequest;
};

export const placeOrder = (placeOrderParams) => async (dispatch) => {
	dispatch({ type: ORDER_PLACE_REQUESTED });

	const response = await authFetch(createPlaceOrderRequest(placeOrderParams));
	const json = await response.json();

	dispatch(AlertActions.removeAllAlerts("checkout.general"));

	if (json.orderPlaced) {
		await dispatch(CheckoutActions.fetchCheckout());

		dispatch({ type: ORDER_PLACE_DONE });
	} else {
		await redirect(json, true);
	}
};

export const confirmCheckout =
	({ intl, ...placeOrderParams }) =>
	async (dispatch) => {
		try {
			dispatch(CheckoutActions.showLoader());
			dispatch({ type: CheckoutActions.CHECKOUT_CONFIRM_REQUESTED });

			await dispatch(placeOrder(placeOrderParams));

			dispatch({ type: CheckoutActions.CHECKOUT_CONFIRM_DONE });
		} catch (error) {
			await handleOrderPlacedError(error, dispatch, intl);

			dispatch(CheckoutActions.hideLoader());
		}
	};

const createPlaceOrderRequest = (placeOrderParams) => ({
	url: `api/orders/${getCheckoutId()}`,
	method: "POST",
	body: JSON.stringify(createOrderPayload(placeOrderParams)),
});

const handleOrderPlacedError = async (error, dispatch, intl) => {
	const errorBodyMessage = error?.body?.message || error?.body?.Message;
	const id = errorBodyMessage || error || "unexpected error when placing order";

	await sendPaymentEvent(
		"errorWhenPlacingOrder",
		intl.formatMessage({
			id,
		}),
	);

	handleErrorStatus(error, ORDER_PLACE_FAILED, dispatch);

	if (error.status) {
		error.status === 400
			? await dispatch(CheckoutActions.fetchCheckout())
			: dispatch(
					AlertActions.addAlert(
						"checkout.general",
						ALERT_LEVELS.WARN,
						"placeOrder.alert.place",
					),
			  );
	} else {
		dispatch(
			AlertActions.addAlert(
				"checkout.general",
				ALERT_LEVELS.ERROR,
				"network.lost",
			),
		);
	}
};
