import {
	InfoDescription,
	InfoSection,
} from "@components/v2/components/Summary/FairownPaymentPlanInfo/FairownPaymentPlanInfo.styles";
import React, { useRef } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

const FairownPaymentPlanInfo = () => {
	const genericKey = useRef(0);
	const { formatMessage, formatNumber } = useIntl();
	const period = "24";
	const fairownPricingValues = useSelector(
		(state) => state.payment?.subscriptionMethod.options[period].paymentPlan,
	);

	return (
		<InfoSection data-testid="summary.fairownPaymentPlanInfo">
			{formatMessage(
				{ id: "summary.fairownPaymentPlanInfo.description" },
				{
					...fairownPricingValues,
					effectiveInterestRate: formatNumber(
						fairownPricingValues.effectiveInterestRate,
						{
							style: "decimal",
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						},
					),
					p: (...str) => (
						<InfoDescription key={++genericKey.current}>{str}</InfoDescription>
					),
					span: (...str) => (
						<span
							key={++genericKey.current}
							className="fairownPaymentPlanInfo.value"
						>
							{str}
						</span>
					),
				},
			)}
		</InfoSection>
	);
};

export default FairownPaymentPlanInfo;
