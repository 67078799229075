import { ALERT_LEVELS } from "@constants/constants";
import { GTM_EVENT_NAMES, pushToDataLayerAsync } from "@utils/GTMUtils";

import * as AlertActions from "../actions/alert.actions";

import * as CheckoutActions from "./checkout.actions";
import { hideLoader } from "./checkout.actions";
import authFetch from "./utils/common/authFetch";
import getCheckoutId from "./utils/common/getCheckoutId";
import handleErrorStatus from "./utils/common/handleErrorStatus";

/* action types */

export const SHIPPING_SELECT_REQUESTED = "SHIPPING_SELECT_REQUESTED";
export const SHIPPING_SELECT_DONE = "SHIPPING_SELECT_DONE";
export const SHIPPING_SELECT_FAILED = "SHIPPING_SELECT_FAILED";

export const DISABLE_SECTION = "DISABLE_SECTION";
export const ENABLE_SECTION = "ENABLE_SECTION";

export const SET_PORTER_INSTRUCTIONS = "SET_PORTER_INSTRUCTIONS";

/* action creators */

export const selectShipping =
	(logisticUnitId, methodId, optionId, extraServiceIds) =>
	async (dispatch, getState) => {
		const validatedExtraServiceIds = extraServiceIds ?? [];

		dispatch(CheckoutActions.showLoader());
		dispatch(CheckoutActions.enableConfirmButton());

		const {
			order: { logisticUnits },
		} = getState();

		const {
			shipping: { availableShipping },
		} = logisticUnits[0];

		const selectedMethodOptions = availableShipping.find(
			(availableShipping) => availableShipping.typeCode === methodId,
		)?.options;

		const optionKeys = {
			pickupinstore: "transporter",
			homedelivery: "transporter",
			pickuppoint: "postCode",
			carryin: "carryin",
		};

		const selectedOption = selectedMethodOptions.find(
			(selectedMethodOption) => selectedMethodOption.id === optionId,
		)?.[optionKeys[methodId]];

		dispatch({
			type: SHIPPING_SELECT_REQUESTED,
			payload: { logisticUnitId, methodId, optionId, validatedExtraServiceIds },
		});

		try {
			await authFetch({
				url: `/api/checkouts/${getCheckoutId()}/logisticUnits/${logisticUnitId}/selectedShipping/${optionId}`,
				method: "PUT",
				body: JSON.stringify(validatedExtraServiceIds),
			});

			await pushToDataLayerAsync({
				data: {
					event: GTM_EVENT_NAMES.DELIVERY_METHOD_SELECTED,
					selected: selectedOption ? `${methodId} ${selectedOption}` : methodId,
				},
			});

			dispatch({ type: SHIPPING_SELECT_DONE });
			dispatch(AlertActions.removeAllAlerts("checkout.order.general"));
			dispatch(AlertActions.removeAllAlerts("checkout.payment.giftCard"));

			await dispatch(CheckoutActions.fetchCheckout());
		} catch (error) {
			handleErrorStatus(error, SHIPPING_SELECT_FAILED, dispatch);

			dispatch(
				error.status
					? AlertActions.addAlert(
							"checkout.shipping.general",
							ALERT_LEVELS.WARN,
							"order.shipping.alert.select",
					  )
					: AlertActions.addAlert(
							"checkout.general",
							ALERT_LEVELS.ERROR,
							"network.lost",
					  ),
			);
		} finally {
			dispatch(hideLoader());
		}
	};

export function disableSection() {
	return (dispatch) => dispatch({ type: DISABLE_SECTION });
}

export function enableSection() {
	return (dispatch) => dispatch({ type: ENABLE_SECTION });
}

export const setPorterBuddyInstructions = (instructions) => {
	return (dispatch) =>
		dispatch({ type: SET_PORTER_INSTRUCTIONS, payload: { instructions } });
};
