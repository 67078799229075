import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

const Menu = ({
	handleActivate,
	className,
	active,
	automationid,
	children,
}) => {
	const handleOptionClick = (e, id) => {
		e.stopPropagation();
		handleActivate(id);
	};

	const handleOptionKeyDown = (e, id) => {
		e.stopPropagation();

		if (e.key === "Enter") {
			handleActivate(id);
		}
	};

	return (
		<ul className={className} role="tablist">
			{children.map((child, i) => {
				const isActive = active === child.props.id;
				return (
					<li
						key={`${className}-item-${i}`}
						className={classNames("option", { active: isActive })}
						onClick={(e) => handleOptionClick(e, child.props.id)}
						onKeyDown={(e) => handleOptionKeyDown(e, child.props.id)}
						data-automationid={`${automationid}.options.${child.props.id}`}
					>
						<button
							type="button"
							role="tab"
							className="tab-button"
							aria-selected={isActive}
							disabled={child.props.disabled}
						>
							{child}
						</button>
					</li>
				);
			})}
		</ul>
	);
};

Menu.propTypes = {
	className: PropTypes.string,
	automationid: PropTypes.string,
	active: PropTypes.string,
	children: PropTypes.array,
	handleActivate: PropTypes.func,
};

export default Menu;
