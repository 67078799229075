import Modal from "@components/modal/Modal";
import { FinanceModalContent } from "@components/payment/methods/financePaymentMethod/FinanceModalContent";
import Button from "@components/v2/components/buttons/Button";
import FairownSubscriptionAgreements from "@components/v2/components/payments/methods/financePaymentMethod/FairownSubscriptionAgreements";
import { InfoWrapper } from "@components/v2/components/payments/methods/financePaymentMethodInfo/FinancePaymentMethodInfo.styles";
import PaymentMethodDescription from "@components/v2/components/payments/methods/financePaymentMethodInfo/PaymentMethodDescription";
import { BUTTON_VARIANTS } from "@components/v2/constants";
import { PAYMENT_METHOD_NAMES } from "@constants/constants";
import React, { useState } from "react";
import { useIntl } from "react-intl";

const FinancePaymentMethodInfo = ({
	type,
	selectedOption: { paymentPlan, cost, code },
	options,
}) => {
	const { formatMessage } = useIntl();
	const [isModalOpen, setIsModalOpen] = useState(false);

	const isFairownSubscription =
		type === PAYMENT_METHOD_NAMES.FAIROWN_SUBSCRIPTION;

	const methodTypePrefix = `payment.methods.${type}`;

	const handleTriggerModal = (e) => {
		e.preventDefault();
		setIsModalOpen(!isModalOpen);
	};

	const shouldShowPaymentTermsContent = ![
		PAYMENT_METHOD_NAMES.FAIROWN_SUBSCRIPTION,
		PAYMENT_METHOD_NAMES.KOMPLETT_SAP_INVOICE,
		PAYMENT_METHOD_NAMES.WALLEY_INVOICE,
		PAYMENT_METHOD_NAMES.WALLEY_DEFERRED,
		PAYMENT_METHOD_NAMES.WALLEY_INSTALLMENT,
	].includes(type);

	return (
		<InfoWrapper isFairownSubscription={isFairownSubscription}>
			<PaymentMethodDescription
				type={type}
				options={options}
				paymentPlan={paymentPlan}
				code={code}
				cost={cost}
			/>
			{shouldShowPaymentTermsContent && (
				<>
					{" "}
					<Button
						id={`${methodTypePrefix}.modalTrigger`}
						variant={BUTTON_VARIANTS.LINK}
						aria-expanded={isModalOpen}
						onClick={handleTriggerModal}
					>
						{formatMessage({ id: `${methodTypePrefix}.links.readmore.text` })}
					</Button>
					<Modal
						isOpen={isModalOpen}
						onRequestClose={handleTriggerModal}
						className="finance-payment-terms-modal"
						aria-labelledby="financePaymentTermsModalHeader"
						contentLabel="financePaymentTermsModalContent"
					>
						<FinanceModalContent methodTypePrefix={methodTypePrefix} />
					</Modal>
				</>
			)}
			{isFairownSubscription && (
				<FairownSubscriptionAgreements
					handleTriggerModal={handleTriggerModal}
					isModalOpen={isModalOpen}
				/>
			)}
		</InfoWrapper>
	);
};

export default FinancePaymentMethodInfo;
