import Modal from "@components/modal/Modal";
import Button from "@components/v2/components/buttons/Button";
import {
	FairownDivider,
	FairownSubscriptionAgreementsWrapper,
	StyledFlexConsent,
} from "@components/v2/components/payments/methods/financePaymentMethod/FairownSubscriptionAgreements.styles";
import { BUTTON_VARIANTS, DIVIDER_VARIANTS } from "@components/v2/constants";
import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

const FairownSubscriptionAgreements = ({ isModalOpen, handleTriggerModal }) => {
	const storeUrl = useSelector((state) => state.checkout.storeUrl);

	const { formatMessage } = useIntl();

	return (
		<FairownSubscriptionAgreementsWrapper>
			<FairownDivider variant={DIVIDER_VARIANTS.DASHED} />
			<p>
				{formatMessage({ id: "payments.fiarownsubscription.consent" })}{" "}
				<Button
					variant={BUTTON_VARIANTS.LINK}
					aria-expanded={isModalOpen}
					onClick={handleTriggerModal}
				>
					{formatMessage({ id: "payments.fiarownsubscription.consent.button" })}
				</Button>
			</p>
			<Modal
				isOpen={isModalOpen}
				onRequestClose={handleTriggerModal}
				className="finance-payment-terms-modal"
				aria-labelledby="financePaymentTermsModalHeader"
				contentLabel="financePaymentTermsModalContent"
			>
				<StyledFlexConsent
					text={formatMessage(
						{ id: "payments.fiarownsubscription.consent.description" },
						{
							value: `${storeUrl}`,
						},
					)}
					label={formatMessage({
						id: "payments.fiarownsubscription.consent.checkbox.label",
					})}
					apiHost={new URL(storeUrl).hostname}
				/>
			</Modal>
		</FairownSubscriptionAgreementsWrapper>
	);
};

export default FairownSubscriptionAgreements;
