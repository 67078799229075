export default (getCheckoutResponse) => {
	const offers = getCheckoutResponse.order.logisticUnits
		? getCheckoutResponse.order.logisticUnits
				.map((lu) =>
					lu.offers.map((o) => ({
						...o,
						shopId: lu.shop.id,
						shopName: lu.shop.name,
					})),
				)
				.reduce((a, b) => a.concat(b), [])
		: [];

	return {
		event: "checkoutLoadedEvent",
		containerId: "GTM-KRFG2W5",
		checkoutId: getCheckoutResponse.id,
		pageType: "Checkout",
		initialCheckoutType: "KomPay",
		initialPaymentMethod:
			getCheckoutResponse?.payment?.subscriptionPayment?.type ||
			getCheckoutResponse?.payment?.directPayment?.initialPaymentType,
		initialFinancingPaymentMethod:
			getCheckoutResponse?.payment?.directPayment
				?.initialFinancingPaymentMethod,
		checkoutOrderType: getCheckoutResponse.checkoutOrderType,
		storeId: getCheckoutResponse.storeId,
		all: {
			customerNumber: getCheckoutResponse.recipient.hashedRecipientId,
			customerType: getCheckoutResponse.recipient.customerType,
		},
		// TODO: This part has to be rewritten to GA4 and remapped for other tracking tools.
		ecommerce: {
			checkout: {
				actionField: { step: 1 },
				products: offers.map((offer) => ({
					id: offer.productId,
					category: offer.category,
					name: offer.name,
					price: offer.discountedOfferPrice,
					quantity: offer.quantity,
					brand: offer.brand,
					groupId: offer.group?.groupId,
					subGroupId: offer.group?.subGroupId,
					groupName: offer.group?.groupName,
					subGroupName: offer.group?.subGroupName,
					divisionName: offer.group?.divisionName,
					divisionId: offer.group?.divisionId,
				})),
			},
		},
	};
};
