import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const FlexTitle = styled.h1(
	({ theme: { breakpoints, colors }, isRenewalMode }) => css`
		display: ${isRenewalMode ? "none" : "flex"};
		justify-content: space-between;
		font-size: 20px;
		font-weight: 700;
		border-bottom: 1px dashed ${colors.divider};
		margin-bottom: 16px;
		padding: 16px 24px 32px 16px;

		@media (min-width: ${breakpoints.md}) {
			display: flex;
			font-size: 24px;
			align-items: center;
			padding: 0 8px;
			border-bottom: none;
		}
	`,
);
