import { setSSN, setValidity } from "@actions/payment/methods.actions";
import Validation from "@validation/Validation";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import MaskedInput from "react-text-mask";

const { validateSingleField } = new Validation({
	ssn: [{ name: "required" }, { name: "securityNumber" }],
});

const PersonalDataForm = ({
	type,
	label,
	placeholder,
	mask,
	automationid,
	disabled,
	country
}) => {
	const dispatch = useDispatch();
	const inputRef = useRef(null);

	const [inputValue, setInputValue] = useState("");
	const [error, setError] = useState("");
	const [touched, setIsTouched] = useState(false);

	const isDirectMethodValid = useSelector(
		(state) => state.payment.directMethods[type].isValid,
	);

	const handleOnChange = ({ target: { value } }) => {
		const _rawValue = value.replace(/\D/g, "");

		setInputValue(_rawValue);
		setIsTouched(true);
	};

	useEffect(() => {
		if (!touched) {
			return;
		}

		const { ssn } = validateSingleField("ssn", inputValue, {
			country,
		});

		setError(ssn);
		dispatch(setSSN(inputValue));
		dispatch(setValidity(type, !ssn));
	}, [touched, inputValue, isDirectMethodValid, country, type, dispatch])

	return (
		<form id="personalData">
			<section id="numbers" name="numbers" className="numbers">
				<div className="ssn">
					<label className="label" htmlFor="ssn">
						{label}
					</label>
					<MaskedInput
						data-hj-suppress
						data-skipui-cobrowse
						id="ssn"
						name="ssn"
						className="field"
						type="tel"
						placeholder={placeholder}
						mask={mask}
						onChange={handleOnChange}
						disabled={disabled}
						automationid={`${automationid}.form.ssn`}
						required
						value={inputValue}
						ref={inputRef}
					/>
					<span
						className="error-message"
						aria-live="polite"
						aria-atomic="true"
						id="ssn.error"
					>
						{error && (
							<span className="contents" data-testid="error.message">
								<FormattedMessage
									id={error}
									values={{ fieldname: label }}
								/>
							</span>
						)}
					</span>
				</div>
			</section>
		</form>
	);
};

PersonalDataForm.propTypes = {
	valid: PropTypes.bool,
	label: PropTypes.string,
	placeholder: PropTypes.string,
	automationid: PropTypes.string,
	mask: PropTypes.array,
	country: PropTypes.string,
	disabled: PropTypes.bool,
	type: PropTypes.string
};

export default PersonalDataForm;
