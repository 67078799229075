import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const InfoSection = styled.section(
	({ theme: { breakpoints } }) => css`
		display: grid;
		grid-gap: 4px;
		margin: 0 32px 16px;
		font-size: 14px;
		line-height: 130%;
		padding: 8px;
		border: 1px solid #d2d2d2;
		border-radius: 8px;

		@media (min-width: ${breakpoints.md}) {
			margin: 0;
		}
	`,
);

export const InfoDescription = styled.p`
	line-height: 130%;
`;
