import { ReactComponent as FlexLogo } from "@assets/img/FLEX_logo.svg";
import { FlexTitle } from "@components/payment/SubscriptionPaymentSection.styles";
import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import Panel from "./Panel";
import PaymentHeader from "./PaymentHeader";
import FinancePaymentMethod from "./methods/financePaymentMethod/FinancePaymentMethod";

const SubscriptionPaymentSection = () => {
	const { formatMessage } = useIntl();

	const isRenewalMode = useSelector((state) => state.checkout.isRenewalMode);
	const subscriptionMethod = useSelector(
		(state) => state.payment.subscriptionMethod,
	);
	const subscriptionMonthlyTotal = useSelector(
		(state) =>
			state.summary.subscriptionPaymentSummary?.subscriptionMonthlyTotal?.value,
	);

	const isDisabled = useSelector(
		(state) => state.payment.isSectionDisabled || state.checkout.isDisabled,
	);

	const monthlyPaymentPriceLabelId = isRenewalMode
		? "monthly.payment.section.price.renewal"
		: "monthly.payment.section.price";

	return (
		<section
			className="section-payment-subscription"
			aria-label={formatMessage({
				id: "monthly.payment.section.title",
			})}
		>
			<div className="content">
				<FlexTitle isRenewalMode={isRenewalMode}>
					{formatMessage(
						{ id: monthlyPaymentPriceLabelId },
						{ subscriptionMonthlyTotal },
					)}
					{!isRenewalMode && <FlexLogo />}
				</FlexTitle>
				<Panel
					key={subscriptionMethod.type}
					id={subscriptionMethod.type}
					automationid={subscriptionMethod.type}
					automationIdSection="monthlyPayment"
					active={true}
					hiddenHeader={true}
					header={<PaymentHeader method={subscriptionMethod} />}
					shouldRemoveGiftCard={false}
					onActivate={() => {
						/* no action needs to be taken */
					}}
					disabled={isDisabled}
				>
					<FinancePaymentMethod type={subscriptionMethod.type} version={subscriptionMethod.version} />
				</Panel>
			</div>
			<div className="split-payment-divider" />
		</section>
	);
};

export default SubscriptionPaymentSection;
