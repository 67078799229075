import {
	selectMethodOption,
	setValidity,
} from "@actions/payment/methods.actions";
import Menu from "@components/list/Menu";
import FinancePaymentMethodInfo from "@components/v2/components/payments/methods/financePaymentMethodInfo/FinancePaymentMethodInfo";
import { PAYMENT_METHOD_NAMES } from "@constants/constants";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import PersonalDataForm from "./PersonalDataForm";

const FinancePaymentMethod = ({ type, version }) => {
	const { formatMessage } = useIntl();
	const dispatch = useDispatch();

	const isFairownSubscription =
		type === PAYMENT_METHOD_NAMES.FAIROWN_SUBSCRIPTION;

	const selectPaymentMethod = (state) =>
		isFairownSubscription
			? state.payment.subscriptionMethod
			: state.payment.directMethods[type];

	const options = useSelector((state) => selectPaymentMethod(state).options);
	const optionsOrder = useSelector(
		(state) => selectPaymentMethod(state).optionsOrder,
	);
	const selectedOptionCode = useSelector(
		(state) => selectPaymentMethod(state).selectedOptionCode,
	);
	const optionsAreVisible = useSelector(
		(state) => selectPaymentMethod(state).optionsAreVisible,
	);
	const isDirectMethodValid = useSelector(
		(state) => !!selectPaymentMethod(state).isValid,
	);

	const country = useSelector((state) => state.checkout.storeLanguage);
	const isDisabled = useSelector(
		(state) => state.payment.isSectionDisabled || state.checkout.isDisabled,
	);

	const handleActivate = (id) => dispatch(selectMethodOption(type, id));

	const methodTypePrefix = `payment.methods.${type}`;
	const selectedOption = options[selectedOptionCode];

	useEffect(() => {
		const isValid = selectedOption.requiresSignature
			? isDirectMethodValid
			: true;

		dispatch(setValidity(type, isValid));
	}, [dispatch, isDirectMethodValid, selectedOption.requiresSignature, type]);

	return (
		<div className="finance-payment" data-automationid={`${type}.container`}>
			{optionsAreVisible && (
				<div className="column">
					<Menu
						className="options"
						automationid={type}
						active={selectedOption.code}
						handleActivate={handleActivate}
					>
						{optionsOrder.map((optionCode) => {
							const currentOption = options[optionCode];
							const labelId = `${methodTypePrefix}.options.${currentOption.code}.title`;
							return (
								<div
									disabled={isDisabled}
									className="plan"
									key={currentOption.code}
									id={currentOption.code}
								>
									<FormattedMessage
										id={labelId}
										values={{
											cost: `${
												currentOption.paymentPlan.monthlyCost ||
												currentOption.cost
											}`,
										}}
									/>
								</div>
							);
						})}
					</Menu>
					{selectedOption.requiresSignature && (
						<PersonalDataForm
							form={"paymentMethod-" + type}
							label={formatMessage({
								id: `${methodTypePrefix}.ssn.label`,
							})}
							placeholder={formatMessage({
								id: `${methodTypePrefix}.ssn.placeholder`,
							})}
							mask={[
								/\d/,
								/\d/,
								/\d/,
								/\d/,
								/\d/,
								/\d/,
								/\d/,
								/\d/,
								/\d/,
								/\d/,
								/\d/,
							]}
							country={country}
							automationid={type}
							disabled={isDisabled}
							type={type}
						/>
					)}
				</div>
			)}
			{selectedOption.paymentPlan && (
				<FinancePaymentMethodInfo
					version={version}
					type={type}
					selectedOption={selectedOption}
					options={options}
				/>
			)}
		</div>
	);
};

FinancePaymentMethod.propTypes = {
	type: PropTypes.string,
};

export default FinancePaymentMethod;
