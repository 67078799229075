import { ReactComponent as EllipseChecked } from "@assets/img/icons/elipse-checked.svg";
import { ReactComponent as Ellipse } from "@assets/img/icons/elipse.svg";
import { KEY_MAP } from "@constants/constants";
import dispatchClarityEvent from "@utils/MicrosoftClarityUtils";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

const Panel = ({
	id,
	className = "",
	automationid,
	automationIdSection,
	active,
	hiddenHeader,
	shouldRemoveGiftCard,
	children,
	onActivate,
	onContentClick,
	disabled,
	header,
	collapsible = true,
}) => {
	const activate = () => {
		onActivate(id, shouldRemoveGiftCard);
	};

	const handleHeaderClick = (e) => {
		if (!active) {
			e.stopPropagation();
			activate();

			return;
		}

		dispatchClarityEvent("tryReactivateDeliveryMethod");
	};

	const handleHeaderKeyDown = (e) => {
		if (!active && [KEY_MAP.ENTER, KEY_MAP.SPACE].includes(e.keyCode)) {
			e.stopPropagation();
			e.preventDefault();
			activate();

			return;
		}

		dispatchClarityEvent("reactivateMethod");
	};

	const a11yId = `${id}.panel.content`;

	return (
		<section
			className={classNames("panel", id, className, { active })}
			role="radio"
			id={id}
			onClick={handleHeaderClick}
			onKeyDown={handleHeaderKeyDown}
		>
			<h1
				className={classNames("header", { hidden: hiddenHeader })}
				data-automationid={`${automationid}.panel.header`}
			>
				<div
					role="button"
					aria-pressed={active}
					className={classNames("trigger", id.toLowerCase(), {
						active,
					})}
					tabIndex={0}
					aria-controls={a11yId}
					aria-expanded={active}
					disabled={disabled}
					data-automationid={`${automationIdSection}.button.trigger`}
				>
					{active ? <EllipseChecked /> : <Ellipse />}
					{header}
				</div>
			</h1>
			{(active || !collapsible) && (
				<div
					className="content"
					onClick={(e) => onContentClick?.(e)}
					aria-labelledby={a11yId}
					data-automationid={`${automationid}.panel.content`}
				>
					{children}
				</div>
			)}
		</section>
	);
};

Panel.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	automationid: PropTypes.string,
	automationIdSection: PropTypes.string,
	active: PropTypes.bool,
	hiddenHeader: PropTypes.bool,
	header: PropTypes.element,
	shouldRemoveGiftCard: PropTypes.bool,
	children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	onActivate: PropTypes.func,
	onContentClick: PropTypes.func,
	disabled: PropTypes.bool,
	collapsible: PropTypes.bool,
};

export default Panel;
