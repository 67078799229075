import { setValidity } from "@actions/payment/methods.actions";
import { PAYMENT_METHODS_WITH_OWN_VALIDATION } from "@constants/constants";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

const usePaymentValidation = () => {
	const dispatch = useDispatch();
	const selectedMethodHasChanged = useRef(false);
	const previousMethod = useRef("");
	const selectedMethod = useSelector(
		(state) =>
			state.payment.selectedMethod || state.payment?.subscriptionMethod?.type,
	);

	const selectedMethodHasOwnValidation = Object.values(
		PAYMENT_METHODS_WITH_OWN_VALIDATION,
	).includes(selectedMethod);

	useEffect(() => {
		if (selectedMethod === previousMethod.current) {
			return;
		}

		previousMethod.current = selectedMethod;
		selectedMethodHasChanged.current = false;
	}, [selectedMethod]);

	useEffect(() => {
		if (selectedMethodHasChanged.current) {
			return;
		}

		if (!selectedMethodHasOwnValidation) {
			dispatch(setValidity(selectedMethod, true));
		}

		selectedMethodHasChanged.current = true;
	}, [
		selectedMethodHasOwnValidation,
		dispatch,
		selectedMethodHasChanged,
		selectedMethod,
	]);
};

export default usePaymentValidation;
