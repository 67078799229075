import CloseButton from "@components/app/utils/CloseButton";
import PropTypes from "prop-types";
import React from "react";
import ReactModal from "react-modal";

ReactModal.setAppElement("#app");

const Modal = ({ children, onRequestClose, ...props }) => {
	return (
		<ReactModal
			{...props}
			role="dialog"
			overlayClassName="overlay-modal"
			closeTimeoutMS={300}
			onRequestClose={onRequestClose}
		>
			<CloseButton onClick={onRequestClose} />
			{children}
		</ReactModal>
	);
};

Modal.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.array,
		PropTypes.node,
	]),
	isOpen: PropTypes.bool,
	onRequestClose: PropTypes.func,
	className: PropTypes.string,
	ariaLabelledBy: PropTypes.string,
	contentLabel: PropTypes.string,
};

export default Modal;
